<template>
  <div>
    <div
      class="input-group input-group-sm custom-form-group mb-0 me-2"
      v-bind:style="[
        searchNameBtnActive ? { width: '328px' } : { width: '275px' },
      ]"
    >
      <input
        type="text"
        v-model="searchfullname"
        class="form-control text-capitalize"
        placeholder="Search By First Or Last Name"
        aria-label="Search"
        aria-describedby="basic-addon1"
        autocomplete="off"
        maxlength="30"
        @keyup.enter="search"
        @keypress="isLetterWithSpace($event)"
        :disabled="hideSearchName"
        style="padding: 6px 15px"
      />
      <button
        type="button"
        class="header-btn-outer btn btn-primary"
        :disabled="searchfullname.length == ''"
        @click="search"
        :style="{ width: '50px' }"
      >
        <span v-if="!searchNameLoader"
          ><i class="pi pi-search" aria-hidden="true"></i
        ></span>
        <div
          class="spinner-border text-light custom-spinner-loader-btn"
          role="status"
          v-if="searchNameLoader"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
      <button
        type="button"
        class="header-btn-outer btn btn-primary ms-1"
        @click="searchStop"
        :style="{ width: '50px' }"
        v-show="searchNameBtnActive"
      >
        <i class="pi pi-times" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</template>

<script>
import commonMixin from "../mixins/commonMixin";
export default {
  mixins: [commonMixin],
  props: {
    searchNameBtnActive: {
      type: Boolean,
    },
    searchNameLoader: {
      type: Boolean,
    },
    hideSearchName: {
      type: Boolean,
    },
    // searchnNameField:{
    //   type:String
    // }
  },
  data() {
    return {
      searchfullname: "",
    };
  },
  methods: {
    search() {
      if (this.searchfullname.trim() !== "") {
        this.$emit("search-name", this.searchfullname.trim());
      }
    },
    searchStop() {
      this.searchfullname="";
      this.$emit("search-stop");
    },
  },
};
</script>

<style>
</style>