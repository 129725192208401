<template>
  <div>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-5 col-12">
      Guest Reported By Host/Police
    </div>
    <div class="col-lg-7 col-12 text-end d-flex justify-content-end">
      <!-- <div
        class="input-group input-group-sm custom-form-group mb-0 me-2"
        v-bind:style="[
          searchnamebtnactive ? { width: '328px' } : { width: '275px' },
        ]"
      >
        <input
          type="text"
          v-model="searchfullname"
          class="form-control text-capitalize"
          placeholder="Search By First Or Last Name"
          aria-label="Search"
          aria-describedby="basic-addon1"
          autocomplete="off"
          maxlength="30"
          @keyup.enter="searchFnameGuest(searchfullname)"
          @keypress="isLetterWithSpace($event)"
          :disabled="hidesearchname"
          style="padding: 6px 15px"
        />
        <button
          type="button"
          class="header-btn-outer btn btn-primary"
          :disabled="searchfullname.length == ''"
          @click="searchFnameGuest(searchfullname)"
          :style="{ width: '50px' }"
        >
          <span v-if="!searchnameloader"
            ><i class="pi pi-search" aria-hidden="true"></i
          ></span>
          <div
            class="spinner-border text-light custom-spinner-loader-btn"
            role="status"
            v-if="searchnameloader"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        <button
          type="button"
          class="header-btn-outer btn btn-primary ms-1"
          @click="searchNameGuestStop()"
          :style="{ width: '50px' }"
          v-show="searchnamebtnactive"
        >
          <i class="pi pi-times" aria-hidden="true"></i>
        </button>
      </div> -->
      <search-by-name
          :searchNameBtnActive="searchnamebtnactive"
          :searchNameLoader="searchnameloader"
          :hideSearchName="hidesearchname"
          @search-name="searchFnameGuest"
          @search-stop="searchNameGuestStop"
        />
      <!-- <div
        class="input-group input-group-sm custom-form-group mb-0"
        v-bind:style="[
          searchmobbtnactive ? { width: '275px' } : { width: '220px' },
        ]"
      >
        <input
          type="text"
          v-model="searchmobile"
          class="form-control"
          id="searchmobInput"
          placeholder="Search By Mobile No."
          :disabled="hidesearchmob"
          aria-label="Search"
          aria-describedby="basic-addon1"
          autocomplete="off"
          maxlength="10"
          @keypress="mobileSearchFormat"
          style="padding: 6px 15px"
        />
        <button
          type="button"
          class="header-btn-outer btn btn-primary"
          :disabled="searchmobile.length <= 3"
          @click="searchMobGuest(searchmobile)"
          :style="{ width: '50px' }"
        >
          <span v-if="!searchmobloader"
            ><i class="pi pi-search" aria-hidden="true"></i
          ></span>
          <div
            class="spinner-border text-light custom-spinner-loader-btn"
            role="status"
            v-if="searchmobloader"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        <button
          type="button"
          class="header-btn-outer btn btn-primary ms-1"
          @click="searchMobGuestStop()"
          :style="{ width: '50px' }"
          v-show="searchmobbtnactive"
        >
          <i class="pi pi-times" aria-hidden="true"></i>
        </button>
      </div> -->
      <!-- <button
        type="button"
        @click="guestFilterModalOpen()"
        class="header-filter-btn-outer btn btn-light"
        title="Filter"
      >
        <span class="custom-filter-apply-alert"></span>
        <i class="pi pi-filter-fill custom-text-primary" aria-hidden="true"></i>
      </button> -->
    </div>
  </div>
  <!-- datatable start here -->
  <div class="company-section-outer">
    <div class="layout-content-section">
      <div
        class="custom-ultima-datatable custom-voter-datable"
        style="height: calc(100vh - 141px)"
      >
        <DataTable
          :value="guestList"
          :scrollable="true"
          columnResizeMode="fit"
          scrollHeight="flex"
          :paginator="true"
          :rows="30"
          :totalRecords="totalRecords"
          @page="changePage($event)"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          responsiveLayout="scroll"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          :lazy="true"
          dataKey="aa1"
          v-model:selection="selectedvoter"
          :loading="loading"
        >
          <template #empty>No records found.</template>
          <template #loading>Loading records. Please wait...</template>
          <Column
            headerStyle="width: 3%"
            bodyStyle="width: 3%"
            class="justify-content-center"
            selectionMode="multiple"
          ></Column>
          <Column
            header="Select All"
            headerStyle="width: 96%"
            bodyStyle="width: 96%"
          >
            <template #body="{ data }">
              <div class="company-detail-box-outer mb-3">
                <div class="row">
                  <div class="col-lg-3 col-md-3 col-12 user-name-table-col">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Guest Name</label>
                          <div class="from-label-value text-capitalize">
                            {{ data.aw3 ? data.aw3 : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Reported Reason</label>
                          <div class="from-label-value text-capitalize">
                            {{ data.aw7 ? data.aw7 : "N/A" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-12 user-name-table-col">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">User Name</label>
                          <div class="from-label-value text-capitalize">
                            {{ data.aw5 ? data.aw5 : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Reason Details</label>
                          <div class="from-label-value text-capitalize">
                            <span
                              v-if="!activate_read_key_arr.includes(data.aw8)"
                              >{{ data.aw8.slice(0, 50).replaceAll("*", "") }}
                            </span>
                            <a
                              v-if="
                                data.aw8.length >= 50 &&
                                !activate_read_key_arr.includes(data.aw8)
                              "
                              @click="activateReadMore(data.aw8)"
                              class="showmorebody ps-1"
                              >read more</a
                            >
                            <span
                              v-if="activate_read_key_arr.includes(data.aw8)"
                              v-html="data.aw8.replaceAll('*', '')"
                            ></span>
                            <span>
                              <a
                                v-if="
                                  data.aw8.length >= 50 &&
                                  activate_read_key_arr.includes(data.aw8)
                                "
                                @click="deactivateReadMore(data.aw8)"
                                class="showlessbody ps-1"
                                >read less</a
                              >
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-12 user-name-table-col">
                    <div class="row">
                      <div class="d-flex justify-content-between">
                        <div class="col-lg-6 col-md-6 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Reported By</label>
                            <div class="from-label-value text-capitalize">
                              <span v-if="data.aw10 == 1">{{ "Admin" }}</span>
                              <span v-else-if="data.aw10 == 2">{{
                                "Host"
                              }}</span>
                              <span v-else-if="data.aw10 == 3">{{
                                "Police"
                              }}</span>
                              <span v-else>{{ "N/A" }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Reg. Date Time</label>
                            <div class="from-label-value">
                              {{ format_timestamp(data.aw9) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
  <!-- datatable end here -->
  <!-- filter start here -->
  <!-- <div class="modal-mask" v-if="guestfiltermodalsflag">
    <div
      class="modal-md modal-dialog-scrollable modal-fullscreen ms-auto custom-modal-outer"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
          <button
            type="button"
            class="btn-close"
            @click="guestFilterModalClose()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Guest Type</label>
                    <div class="custom-group-radio-box-btn">
                      <input
                        type="radio"
                        class="btn-check"
                        v-model="aa30"
                        name="visitor-type-option"
                        id="indian"
                        autocomplete="off"
                        value="1"
                      />
                      <label
                        class="btn custom-radio-box-btn text-capitalize"
                        for="indian"
                        >Indian</label
                      >
                      <input
                        type="radio"
                        class="btn-check"
                        v-model="aa30"
                        name="visitor-type-option"
                        id="foreigner"
                        autocomplete="off"
                        value="2"
                      />
                      <label
                        class="btn custom-radio-box-btn text-capitalize"
                        for="foreigner"
                        >Foreigner</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Visitor Reason</label>
                    <Multiselect
                      v-model="aa49"
                      :options="reasonList"
                      mode="tags"
                      :createTag="false"
                      :searchable="true"
                      label="label"
                      placeholder="Select"
                      class="multiselect-custom text-capitalize"
                      :canClear="true"
                      :closeOnSelect="true"
                      :object="false"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Gender</label>
                    <Multiselect
                      v-model="aa12"
                      :options="genderList"
                      mode="tags"
                      :createTag="false"
                      label="label"
                      class="multiselect-custom text-capitalize"
                      placeholder="Select"
                      :closeOnSelect="true"
                      :searchable="false"
                      :object="false"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-5 col-md-5 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Age</label>
                    <input
                      type="text"
                      v-model="agerange"
                      class="form-control"
                      placeholder="Enter Age"
                      minlength="1"
                      maxlength="2"
                      @keypress="onlysingleagechange"
                      autocomplete="off"
                    />
                    <span class="custom-error" v-if="agerangeeerr">
                      {{ agerangeeerr }}
                    </span>
                  </div>
                </div>
                <div class="col-lg-2 col-md-2 col-12">
                  <div
                    class="customer-label-group text-center"
                    style="margin-top: 35px"
                  >
                    <label class="from-label-value">-- OR --</label>
                  </div>
                </div>
                <div class="col-lg-5 col-md-5 col-12">
                  <div class="row custom-form-group">
                    <div class="col-lg-5 col-md-5 col-12">
                      <div class="custom-form-group mb-0">
                        <label class="form-label">Age Range</label>
                        <input
                          type="text"
                          v-model="agerangefrom"
                          class="form-control"
                          placeholder="From"
                          minlength="1"
                          maxlength="2"
                          @keypress="agerangechange"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                      <div
                        class="custom-form-group text-center mb-0"
                        style="margin-top: 35px"
                      >
                        -
                      </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-12">
                      <div
                        class="custom-form-group mb-0"
                        style="margin-top: 5px"
                      >
                        <label class="form-label"></label>
                        <input
                          type="text"
                          v-model="agerangeto"
                          :disabled="agerangefrom == ''"
                          class="form-control"
                          placeholder="To"
                          minlength="1"
                          maxlength="2"
                          @keypress="agerangechange"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <span class="custom-error" v-if="agerangefromeerr">
                      {{ agerangefromeerr }}
                    </span>
                    <span class="custom-error" v-if="agerangetoeerr">
                      {{ agerangetoeerr }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Country</label>
                    <Multiselect
                      v-model="aa18"
                      :options="countrynameList"
                      mode="tags"
                      :createTag="false"
                      :searchable="true"
                      label="label"
                      placeholder="Select"
                      class="multiselect-custom text-capitalize"
                      :canClear="true"
                      :closeOnSelect="true"
                      :object="false"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Area</label>
                    <Multiselect
                      v-model="aa24"
                      :options="filterareaList"
                      mode="tags"
                      :createTag="false"
                      :searchable="true"
                      label="label"
                      placeholder="Select"
                      class="multiselect-custom text-capitalize"
                      :canClear="true"
                      :closeOnSelect="true"
                      :object="false"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Pincode</label>
                    <input
                      type="text"
                      v-model="pincodevalue"
                      id="filterpincodeInput"
                      class="form-control"
                      placeholder="Enter Pincode"
                      minlength="6"
                      maxlength="6"
                      @keypress="onlypincodeFilterFormat"
                      autocomplete="off"
                    />
                    <span class="custom-error" v-if="pincodeerr">
                      {{ pincodeerr }}
                    </span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12" v-if="this.aa30 == 1">
                  <div class="custom-form-group mb-0">
                    <label class="form-label">Aadhar No.</label>
                    <input
                      type="text"
                      v-model="aadharcardnumber"
                      class="form-control text-uppercase"
                      id="formaadharcardinput"
                      placeholder="Enter Aadhar No."
                      autocomplete="off"
                      minlength="12"
                      maxlength="12"
                      @keypress="onlyNumber"
                    />
                    <span class="custom-error" v-if="aadharerr">
                      {{ aadharerr }}
                    </span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12" v-if="this.aa30 == 2">
                  <div class="custom-form-group mb-0">
                    <label class="form-label">Passport No.</label>
                    <input
                      type="text"
                      v-model="aa41"
                      class="form-control text-uppercase"
                      id="formaadharcardinput"
                      placeholder="Enter Passport No."
                      autocomplete="off"
                      minlength="8"
                      maxlength="15"
                      @keypress="onlyNumber"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 m-0">
            <div class="d-flex justify-content-between">
              <button
                class="btn filter-apply-btn"
                :disabled="
                  (aa30 == '' &&
                    aa49 == '' &&
                    aa12 == '' &&
                    agerange == '' &&
                    agerangefrom == '' &&
                    agerangeto == '' &&
                    aa18 == '' &&
                    pincodevalue == '' &&
                    aa24 == '' &&
                    aadharcardnumber == '' &&
                    aa41 == '') ||
                  this.agerangeeerr != '' ||
                  this.agerangefromeerr != '' ||
                  this.agerangetoeerr != '' ||
                  this.pincodeerr != '' ||
                  this.aadharerr != ''
                "
                @click="
                  btnFilterSearch(
                    aa30,
                    aa49,
                    aa12,
                    agerange,
                    agerangefrom,
                    agerangeto,
                    aa18,
                    pincodevalue,
                    aa24,
                    aadharcardnumber,
                    aa41
                  )
                "
              >
                Apply Filter
              </button>
              <button
                class="btn btn-link filter-reset-btn"
                @click="resetGuestFilter"
              >
                Reset All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- filter end here -->
  <!-- next action modal start here -->
  <!-- <div class="modal-mask" v-if="viewguestdetailstatus">
        <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header custom-custmer-header-border">
                    <h5 class="modal-title" id="staticBackdropLabel">Guest Details</h5>
                    <button type="button" class="btn-close" @click="guestDetailModalClose()"></button>
                </div>
                <ul class="nav nav-tabs custom-customer-modal-tab" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="guest-history-tab" data-bs-toggle="tab"
                            data-bs-target="#guesthistorydetails" type="button" role="tab" aria-controls="appointment-history"
                            aria-selected="true" @click="getVisitorHistroyByVisitorId()">
                            Guest History
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="guest-aadharcard-tab" data-bs-toggle="tab"
                            data-bs-target="#guestaadhardetails" type="button" role="tab" aria-controls="vehicle-details"
                            aria-selected="false" @click="getaadhardetails()">
                            Aadhar
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="guest-driving-tab" data-bs-toggle="tab"
                            data-bs-target="#guestdrivingdetails" type="button" role="tab" aria-controls="contact-history"
                            aria-selected="false" @click="getlicensedetails">
                            Driving Licence
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="guest-passport-tab" data-bs-toggle="tab"
                            data-bs-target="#guestpassportdetails" type="button" role="tab" aria-controls="contact-passport"
                            aria-selected="false" @click="getPassportdetails">
                            Passport
                        </button>
                    </li>
                </ul>
                <div class="modal-body modal-body-scroll-outer">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="guesthistorydetails" role="tabpanel"
                            aria-labelledby="guest-history-tab">
                            <div class="modal-scroll-min-height">
                                <div v-if="!showloaderguesthistorymodal">
                                    <div class="row" v-if="guestVisitingHistoryTabDetails != null">
                                        <div class="col-lg-12 col-md-12 col-12" v-for="guesthistory in guestVisitingHistoryTabDetails" :key="guesthistory">
                                            <div class="voter-exit-box-outer mb-3">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="row">
                                                            <div class="col-lg-12 col-md-12 col-12">
                                                                <div class="customer-label-group mb-2">
                                                                    <label class="form-label">Guest Name</label>
                                                                    <div class="from-label-value text-capitalize">
                                                                    {{
                                                                        guesthistory.ab3
                                                                        ? guesthistory.ab3
                                                                        : "N/A"
                                                                    }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12 col-12">
                                                                <div class="customer-label-group mb-2">
                                                                    <label class="form-label">Host Name</label>
                                                                    <div class="from-label-value text-capitalize">
                                                                    {{
                                                                        guesthistory.ab5
                                                                        ? guesthistory.ab5
                                                                        : "N/A"
                                                                    }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12 col-12">
                                                            <div class="customer-label-group mb-2">
                                                                <label class="form-label">Attended By</label>
                                                                <div class="from-label-value text-capitalize">
                                                                {{
                                                                    guesthistory.z508
                                                                    ? guesthistory.z508
                                                                    : "N/A"
                                                                }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="row">
                                                            <div class="col-lg-12 col-md-12 col-12">
                                                                <div class="customer-label-group mb-2">
                                                                    <label class="form-label">Visiting From<span
                                                            class="mx-1 vertical-pipe-color">|</span>To Date</label>
                                                                    <div class="from-label-value text-capitalize">
                                                                        {{ format_timestamp(guesthistory.ab6) }}<span
                                                            class="mx-1 vertical-pipe-color">|</span>
                                                            {{ format_timestamp(guesthistory.ab7) }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12 col-12">
                                                                <div class="customer-label-group mb-2">
                                                                    <label class="form-label">Branch Name</label>
                                                                    <div class="from-label-value text-capitalize">
                                                                    {{
                                                                        guesthistory.ab9
                                                                        ? guesthistory.ab9
                                                                        : "N/A"
                                                                    }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12 col-12">
                                                                <div class="customer-label-group mb-2">
                                                                    <label class="form-label">Last Updated Date</label>
                                                                    <div class="from-label-value text-capitalize">
                                                                        {{ format_timestamp(guesthistory.z506) }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-else>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group">
                                                <label class="form-label">No records found.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showloaderguesthistorymodal">
                                    <div class="custom-modal-spinner-loader">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="guestaadhardetails" role="tabpanel"
                            aria-labelledby="guest-aadharcard-tab">
                            <div class="modal-scroll-min-height">
                                <div v-if="!showloaderaadharmodal">
                                    <div class="row" v-if="AadharTabDetails != ''">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="voter-exit-box-outer mb-3">
                                                <div class="row">
                                                    <div class="col-lg-2 col-md-2 col-12 d-flex align-items-center ">
                                                        <div class="customer-label-group">
                                                            <div class="d-flex align-items-center">
                                                                <div>
                                                                    <img v-if="AadharTabDetails.ag12 == null || AadharTabDetails.ag12 == ''"
                                                                        src="/assets/images/profile.svg" class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                    " alt="" style="width: 72px; height: 72px;" />
                                                                    <img v-if="AadharTabDetails.ag12 != null && AadharTabDetails.ag12 != ''"
                                                                        :src="'https://storage.googleapis.com/' + this.hoststorageimgpath + '/visitor/aadharphoto/' + AadharTabDetails.ag12"
                                                                        class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                    " alt="" style="width: 72px; height: 72px;" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="row">
                                                            <div class="col-lg-12 col-md-12 col-12">
                                                                <div class="customer-label-group mb-2">
                                                                    <label class="form-label">Name</label>
                                                                    <div class="from-label-value text-capitalize">
                                                                        {{
                                                                            AadharTabDetails.ag4
                                                                            ? AadharTabDetails.ag4
                                                                            : "N/A"
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12 col-12">
                                                                <div class="customer-label-group mb-2">
                                                                    <label class="form-label">Aadhar Number</label>
                                                                    <div class="from-label-value" v-if="isShowAadharNo == 1">
                                                                        {{ AadharTabDetails.ag3 ? AadharTabDetails.ag3 :
                                                                            "N/A" }}
                                                                    </div>
                                                                    <div class="from-label-value" v-if="isShowAadharNo == 1">
                                                                        {{
                                maskedNumber(
                                  AadharTabDetails.ag3
                                    ? AadharTabDetails.ag3
                                    : "N/A"
                                )
                              }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Date of Birth</label>
                                                            <div class="from-label-value">
                                                                {{ format_date(AadharTabDetails.ag8) }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Gender</label>
                                                            <div class="from-label-value">
                                                                {{
                                                                    AadharTabDetails.ag24
                                                                    ? AadharTabDetails.ag24 == 1
                                                                        ? "Male"
                                                                        : AadharTabDetails.ag24 == 2
                                                                            ? "Female"
                                                                            : "Others"
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">House</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    AadharTabDetails.ag27
                                                                    ? AadharTabDetails.ag27
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Landmark</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    AadharTabDetails.ag28
                                                                    ? AadharTabDetails.ag28
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Street</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    AadharTabDetails.ag26
                                                                    ? AadharTabDetails.ag26
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Location</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    AadharTabDetails.ag19
                                                                    ? AadharTabDetails.ag19
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Post Office</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    AadharTabDetails.ag16
                                                                    ? AadharTabDetails.ag16
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Area</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    AadharTabDetails.ag20
                                                                    ? AadharTabDetails.ag20
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">City</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    AadharTabDetails.ag17
                                                                    ? AadharTabDetails.ag17
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Sub Dist</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    AadharTabDetails.ag25
                                                                    ? AadharTabDetails.ag25
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">State</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    AadharTabDetails.ag14
                                                                    ? AadharTabDetails.ag14
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-8 col-md-8 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Address</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    AadharTabDetails.ag6
                                                                    ? AadharTabDetails.ag6
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Pincode</label>
                                                            <div class="from-label-value">
                                                                {{
                                                                    AadharTabDetails.ag7
                                                                    ? AadharTabDetails.ag7
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-else>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group">
                                                <label class="form-label">No records found.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showloaderaadharmodal">
                                    <div class="custom-modal-spinner-loader">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="guestdrivingdetails" role="tabpanel"
                            aria-labelledby="guest-driving-tab">
                            <div class="modal-scroll-min-height">
                                <div v-if="!showloaderdrivingmodal">
                                    <div v-if="LicenseTabDetails != ''">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="voter-exit-box-outer mb-3">
                                                <div class="row">
                                                    <div class="col-lg-2 col-md-2 col-12 d-flex align-items-center">
                                                        <div class="customer-label-group">
                                                            <div class="d-flex align-items-center">
                                                                <div>
                                                                    <img v-if="LicenseTabDetails.as19 == null ||
                                                                        LicenseTabDetails.as19 == ''
                                                                        " src="/assets/images/profile.svg" class="rounded bg-light me-auto d-block" alt=""
                                                                        style="width: 72px; height: 72px;" />
                                                                    <img :src="'https://storage.googleapis.com/' + this.hoststorageimgpath + '/visitor/drivingphoto/' + LicenseTabDetails.as19"
                                                                        class="rounded bg-light me-auto d-block" alt="" style="width: 72px; height: 72px;"
                                                                        v-if="LicenseTabDetails.as19 != null ||
                                                                            LicenseTabDetails.as19 != ''
                                                                            " />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="row">
                                                            <div class="col-lg-12 col-md-12 col-12">
                                                                <div class="customer-label-group mb-2">
                                                                    <label class="form-label">License Number</label>
                                                                    <div class="from-label-value text-uppercase">
                                                                        {{
                                                                            LicenseTabDetails.as4
                                                                            ? LicenseTabDetails.as4
                                                                            : "N/A"
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12 col-12">
                                                                <div class="customer-label-group mb-2">
                                                                    <label class="form-label">Name</label>
                                                                    <div class="from-label-value text-capitalize">
                                                                        {{
                                                                            LicenseTabDetails.as3
                                                                            ? LicenseTabDetails.as3
                                                                            : "N/A"
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="row">
                                                            <div class="col-lg-12 col-md-12 col-12">
                                                                <div class="customer-label-group mb-2">
                                                                    <label class="form-label">Date of Issue</label>
                                                                    <div class="from-label-value">
                                                                        {{ format_date(LicenseTabDetails.as15) }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12 col-12">
                                                                <div class="customer-label-group mb-2">
                                                                    <label class="form-label">Date of Expiry</label>
                                                                    <div class="from-label-value">
                                                                        {{ format_date(LicenseTabDetails.as14) }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Date of Birth</label>
                                                            <div class="from-label-value">
                                                                {{ format_date(LicenseTabDetails.as13) }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Gender</label>
                                                            <div class="from-label-value">
                                                                {{
                                                                    LicenseTabDetails.as12
                                                                    ? LicenseTabDetails.as12 == 1
                                                                        ? "Male"
                                                                        : LicenseTabDetails.as12 == 2
                                                                            ? "Female"
                                                                            : "Others"
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Father / Husband Name</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    LicenseTabDetails.as16
                                                                    ? LicenseTabDetails.as16
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-8 col-md-8 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Permanent Address</label>
                                                            <div class="from-label-value">
                                                                {{
                                                                    LicenseTabDetails.as6 ? LicenseTabDetails.as6 : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Permanent Pincode</label>
                                                            <div class="from-label-value">
                                                                {{
                                                                    LicenseTabDetails.as7 ? LicenseTabDetails.as7 : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-8 col-md-8 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Temporary Address</label>
                                                            <div class="from-label-value">
                                                                {{
                                                                    LicenseTabDetails.as8 ? LicenseTabDetails.as8 : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Temporary Pincode</label>
                                                            <div class="from-label-value">
                                                                {{
                                                                    LicenseTabDetails.as9 ? LicenseTabDetails.as9 : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Ola Name</label>
                                                            <div class="from-label-value">
                                                                {{
                                                                    LicenseTabDetails.as10 ? LicenseTabDetails.as10 : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Ola Code</label>
                                                            <div class="from-label-value">
                                                                {{
                                                                    LicenseTabDetails.as11
                                                                    ? LicenseTabDetails.as11
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-else>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group">
                                                <label class="form-label">No records found.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showloaderdrivingmodal">
                                    <div class="custom-modal-spinner-loader">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="guestpassportdetails" role="tabpanel"
                            aria-labelledby="guest-passport-tab">
                            <div class="modal-scroll-min-height">
                                <div v-if="!showloaderpassportmodal">
                                    <div v-if="PassportTabDetails != ''">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="voter-exit-box-outer mb-3">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Guest Name</label>
                                                                    <div class="from-label-value text-uppercase">
                                                                        {{
                                                                            PassportTabDetails.ap3
                                                                            ? PassportTabDetails.ap3
                                                                            : "N/A"
                                                                        }}
                                                                    </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Passport No.</label>
                                                            <div class="from-label-value">
                                                                {{
                                                                            PassportTabDetails.ap6
                                                                            ? PassportTabDetails.ap6
                                                                            : "N/A"
                                                                        }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Date of Birth</label>
                                                            <div class="from-label-value">
                                                                {{ format_date(PassportTabDetails.ap8) }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="customer-label-group mb-2">
                                                            <label class="form-label">Application Type</label>
                                                            <div class="from-label-value text-capitalize">
                                                                {{
                                                                    PassportTabDetails.ap10
                                                                    ? PassportTabDetails.ap10
                                                                    : "N/A"
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-else>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group">
                                                <label class="form-label">No records found.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showloaderpassportmodal">
                                    <div class="custom-modal-spinner-loader">
                                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
  <!-- next action modal end here -->
</div>
</template>
<script>
import searchByName from "../../../components/searchByName.vue";
import commonMixin from "../../../mixins/commonMixin.js";
import useValidate from "@vuelidate/core";
import ApiService from "../../../service/ApiService";
// import moment from 'moment';

export default {
  mixins: [commonMixin],
  components: {
    "search-by-name": searchByName,
  },
  data() {
    return {
      v$: useValidate(),
      activate_read_key_arr: [],
      // allVisitorsList: [],
      rowId: "",
      mobile_number: "",
      AadharTabDetails: "",
      guestVisitingHistoryTabDetails: [],
      LicenseTabDetails: "",
      PassportTabDetails: "",
      guestfiltermodalsflag: false,
      aw3: "",
      aa13: "",
      guestList: [],
      totalRecords: 0,
      page_no: 0,
      viewguestdetailstatus: false,
      errmsg: "",
      errStatus: false,
      selectedvoter: null,
      aadhardivdisplayflag: 0,
      loading: false,
      showloaderguesthistorymodal: false,
      showloaderaadharmodal: false,
      showloaderdrivingmodal: false,
      showloaderpassportmodal: false,
      client_info: "",
      hoststorageimgpath: "",
      searchmobile: "",
      searchmobloader: false,
      searchfullname: "",
      searchnameloader: false,
      searchmobbtnactive: false,
      searchnamebtnactive: false,
      hidesearchname: false,
      hidesearchmob: false,
      reasonList: [],
      countrynameList: [],
      aa30: "",
      aa49: [],
      aa12: [],
      agerange: "",
      agerangeeerr: "",
      agerangefrom: "",
      agerangeto: "",
      agerangefromeerr: "",
      agerangetoeerr: "",
      aa18: [],
      pincodevalue: "",
      aa24: [],
      aadharcardnumber: "",
      aa41: "",
      aadharerr: "",
      pincodeerr: "",
      filterareaList: [],
      genderList: [
        { label: "Male", value: 1 },
        { label: "Female", value: 2 },
        { label: "Other", value: 3 },
      ],
      isShowAadharNo: "",
      isShowMobileNo: "",
      user_info: "",
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  validations() {
    return {};
  },

  mounted() {
    this.getVisitorReports();
  },
  methods: {
    maskedNumber(getvalue) {
      const maskedDigits = "X".repeat(getvalue.length - 6);
      const FirstThreeDigits = getvalue.slice(0, 3);
      const lastThreeDigits = getvalue.slice(-3);
      return FirstThreeDigits + maskedDigits + lastThreeDigits;
    },
    // getAllVisitors() {
    //   this.loading = true;
    //   this.ApiService.getAllVisitors().then((items) => {
    //     if (items.success == true) {
    //       this.allVisitorsList = items.data;
    //       this.totalRecords = items.count;
    //       this.loading = false;
    //       this.searchnameloader = false;
    //       this.hidesearchname = false;
    //     } else {
    //       this.loading = false;
    //       this.lawUserList = null;
    //       this.totalRecords = 0;
    //       this.searchnameloader = false;
    //       this.hidesearchname = false;
    //     }
    //   });
    // },
    changePage(event) {
      this.page_no = event.page;
      this.getVisitorReports({ page_no: this.page_no });
    },
    getCountryList(e) {
      this.ApiService.getCountryList(e).then((data) => {
        if (data.status == 200) {
          this.countrynameList = data.data;
        } else {
          this.countrynameList = null;
        }
      });
    },
    // Search Voter Header Input function start here
    searchFnameGuest(searchfullname) {
      this.hidesearchname = false;
      this.searchnameloader = true;
      this.aw3 = searchfullname;
      this.getVisitorReports({
        page_no: this.page_no,
        aw3: this.aw3,
      });
      this.searchnamebtnactive = true;
    },
    searchNameGuestStop() {
      this.searchnamebtnactive = false;
      this.searchfullname = "";
      this.getVisitorReports({
        page_no: this.page_no,
      });
      this.searchnameloader = false;
      this.hidesearchname = false;
    },
    // Search Voter Header Input function end here
    // getlicensedetails() {
    //   this.showloaderdrivingmodal = true;
    //   this.ApiService.getlicensedetails({ aa1: this.rowId }).then((data) => {
    //     if (data.success == true) {
    //       this.LicenseTabDetails = data.data;
    //       this.showloaderdrivingmodal = false;
    //     } else {
    //       this.showloaderdrivingmodal = false;
    //       this.LicenseTabDetails = "";
    //     }
    //   });
    // },
    // getPassportdetails() {
    //   this.showloaderpassportmodal = true;
    //   this.ApiService.getPassportdetails({ aa1: this.rowId }).then((data) => {
    //     if (data.success == true) {
    //       this.PassportTabDetails = data.data;
    //       this.showloaderpassportmodal = false;
    //     } else {
    //       this.showloaderpassportmodal = false;
    //       this.PassportTabDetails = "";
    //     }
    //   });
    // },
    // getaadhardetails() {
    //   this.showloaderaadharmodal = true;
    //   this.ApiService.getaadhardetails({ aa1: this.rowId }).then((data) => {
    //     if (data.success == true) {
    //       this.AadharTabDetails = data.data;
    //       this.aadhardivdisplayflag = 1;
    //       this.showloaderaadharmodal = false;
    //     } else {
    //       this.aadhardivdisplayflag = 0;
    //       this.showloaderaadharmodal = false;
    //     }
    //   });
    // },
    // guestDetailModalClose() {
    //   this.viewguestdetailstatus = false;
    // },
    // guestFilterModalOpen() {
    //   this.guestfiltermodalsflag = true;
    //   this.getCountryList();
    //   this.getVisitingReason();
    // },
    // guestFilterModalClose() {
    //   this.guestfiltermodalsflag = false;
    // },
    // btnFilterSearch(
    //   aa30,
    //   aa49,
    //   aa12,
    //   agerange,
    //   agerangefrom,
    //   agerangeto,
    //   aa18,
    //   pincodevalue,
    //   aa24,
    //   aadharcardnumber,
    //   aa41
    // ) {
    //   if (
    //     !this.agerangeeerr &&
    //     !this.pincodeerr &&
    //     !this.aadharerr &&
    //     !this.agerangefromeerr &&
    //     !this.agerangetoeerr
    //   ) {
    //     this.guestfiltermodalsflag = false;
    //     this.aa30 = aa30;
    //     this.aa49 = aa49;
    //     this.aa12 = aa12;
    //     this.agerange = agerange;
    //     this.agerangefrom = agerangefrom;
    //     this.agerangeto = agerangeto;
    //     this.aa18 = aa18;
    //     this.pincodevalue = pincodevalue;
    //     this.aa24 = aa24;
    //     this.aadharcardnumber = aadharcardnumber;
    //     this.aa41 = aa41;
    //     //     this.getHostVisitorHistoryList({
    //     //         page_no: this.page_no,
    //     //         aa30: this.aa30,
    //     //         aa49: this.aa49,
    //     //         aa12: this.aa12,
    //     //         aa11: this.agerange,
    //     //         ak19from: this.agerangefrom,
    //     //         ak19to: this.agerangeto,
    //     //         aa18: this.aa18,
    //     //         aa25: this.pincodevalue,
    //     //         aa24: this.aa24,
    //     //         aa33: this.aadharcardnumber,
    //     //         aa41: this.aa41,
    //     //     });
    //   }
    // },
    // onlysingleagechange($event) {
    //   this.agerangefrom = "";
    //   this.agerangeto = "";
    //   this.agerangefromeerr = "";
    //   this.agerangetoeerr = "";
    //   let keyCode = $event.keyCode ? $event.keyCode : $event.which;
    //   if (keyCode < 48 || keyCode > 57) {
    //     $event.preventDefault();
    //   }
    // },
    // validateAadharCardNumber(e) {
    //   this.errStatus = false;
    //   if (!this.validAadharCard(e)) {
    //     this.aadharerr = "Please enter valid aadhar number";
    //     this.errStatus = true;
    //   }
    //   if (this.errStatus == false) {
    //     this.aadharerr = "";
    //     return true;
    //   }
    // },
    validAgeRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    validateAgeRangeFromNumber(e) {
      this.errStatus = false;
      if (!this.validAgeFromRange(e)) {
        this.agerangefromeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (this.agerangefrom == 0) {
        this.agerangefromeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangefromeerr = "";
        return true;
      }
    },
    validateAgeRangeToNumber(e) {
      this.errStatus = false;
      if (!this.validAgeToRange(e)) {
        this.agerangetoeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (parseInt(this.agerangeto) <= parseInt(this.agerangefrom)) {
        this.agerangetoeerr = "Please enter valid age range";
        this.errStatus = true;
      }
      if (this.agerangeto == 0) {
        this.agerangetoeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangetoeerr = "";
        return true;
      }
    },
    validAgeToRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    activateReadMore(e) {
      this.activate_read_key_arr.push(e);
    },

    deactivateReadMore(e) {
      var index = this.activate_read_key_arr.indexOf(e);
      if (index !== -1) {
        this.activate_read_key_arr.splice(index, 1);
      }
    },
    getVisitorReports(e) {
      this.loading = true;
      this.activate_read_key_arr = [];
      this.ApiService.getVisitorReports(e).then((data) => {
        if (data.success == true) {
          this.guestList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.guestList = null;
          this.totalRecords = 0;
          this.loading = false;
        }
        this.searchnameloader = false;
      });
    },
    getVisitingReason(e) {
      this.ApiService.getVisitingReason(e).then((data) => {
        if (data.status == 200) {
          this.reasonList = data.data;
        } else {
          this.reasonList = null;
        }
      });
    },
    mobileSearchFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      var mobileInput = document.getElementById("searchmobInput");
      if (
        (mobileInput.value == "" || mobileInput.length > 1) &&
        (keyCode == 48 ||
          keyCode == 49 ||
          keyCode == 50 ||
          keyCode == 51 ||
          keyCode == 52)
      ) {
        $event.preventDefault();
      }
    },
    // Search Voter Header Input function start here
    // searchMobGuest(
    //     searchmobile
    // ) {
    //     this.hidesearchmob = true;
    //     this.searchmobloader = true;
    //     this.aa13 = searchmobile;
    //     this.getHostVisitorHistoryList({
    //         page_no: this.page_no,
    //         aa13: this.aa13,
    //     });
    //     this.searchmobbtnactive = true;
    // },
    // searchMobGuestStop() {
    //     this.searchmobbtnactive = false;
    //     this.searchmobile = "";
    //     this.getHostVisitorHistoryList({
    //         page_no: this.page_no,
    //     });
    //     this.searchmobloader = false;
    // },
    // Search Voter Header Input function end here

    // getVisitorHistroyByVisitorId() {
    //   this.showloaderguesthistorymodal = true;
    //   this.ApiService.getVisitorHistroyByVisitorId({ aa1: this.rowId }).then(
    //     (data) => {
    //       if (data.success == true) {
    //         this.guestVisitingHistoryTabDetails = data.data;
    //         this.showloaderguesthistorymodal = false;
    //       } else {
    //         this.guestVisitingHistoryTabDetails = null;
    //         this.showloaderguesthistorymodal = false;
    //       }
    //     }
    //   );
    // },
    // guestdetailModalOpen(e) {
    //   this.rowId = e.aa1;
    //   this.viewguestdetailstatus = true;
    //   this.getVisitorHistroyByVisitorId();
    //   this.aadharcardnumber = "";
    // },
    // resetGuestFilter() {
    //     this.aa30 = "";
    //     this.aa49 = null;
    //     this.aa12 = null;
    //     this.agerange = "";
    //     this.agerangefrom = '';
    //     this.agerangeto = '';
    //     this.aa18 = null;
    //     this.pincodevalue = "";
    //     this.aa24 = null;
    //     this.aadharcardnumber = "";
    //     this.aa41 = '';
    //     this.agerangeeerr = '';
    //     this.agerangefromeerr = '';
    //     this.agerangetoeerr = '';
    //     this.pincodeerr = '';
    //     this.aadharerr = '';
    //     this.getHostVisitorHistoryList({ page_no: this.page_no, });
    //     this.guestfiltermodalsflag = false;
    // },
    // agerangechange($event) {
    //     this.agerange = '';
    //     this.agerangeeerr = '';
    //     let keyCode = $event.keyCode ? $event.keyCode : $event.which;
    //     if (keyCode < 48 || keyCode > 57) {
    //         $event.preventDefault();
    //     }
    // },
    // validAadharCard: function (e) {
    //     var re = /([0-9]){12}$/;
    //     return re.test(e);
    // },
    // validatePinCodeNumber(e) {
    //     this.errStatus = false;
    //     if (!this.validPinCode(e)) {
    //         this.pincodeerr = "Please enter valid pincode";
    //         this.errStatus = true;
    //     }
    //     if (this.errStatus == false) {
    //         this.pincodeerr = "";
    //         return true;
    //     }
    // },
    // validPinCode: function (e) {
    //     var re = /^((?!(0))[0-9]{6,6})$/;
    //     return re.test(e);
    // },
    // validateAgeRangeNumber(e) {
    //     this.errStatus = false;
    //     if (!this.validAgeRange(e)) {
    //         this.agerangeeerr = "Please enter minimum 1 digit";
    //         this.errStatus = true;
    //     }
    //     if (this.agerange == 0) {
    //         this.agerangeeerr = "Please enter valid age";
    //         this.errStatus = true;
    //     }
    //     if (this.errStatus == false) {
    //         this.agerangeeerr = "";
    //         return true;
    //     }
    // },
    // validAgeFromRange: function (e) {
    //     var re = /([0-9]){1,2}$/;
    //     return re.test(e);
    // },
  },
};
</script>
<style scoped>
.company-header-box-outer {
  padding: 9px 16px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0;
}

.company-legal-name {
  font-family: "AcuminPro-SemiBold";
  letter-spacing: 0px;
  color: #2b2e38;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
}

.company-detail-box-outer {
  padding: 16px 16px 0;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  width: 100%;
}

.branch-detail-box-outer {
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}

.branch-detail-box-outer .branch-row-box {
  padding: 16px;
  border-bottom: 1px solid #e0e5ed;
}

.branch-detail-box-outer .branch-row-box:last-child {
  border-bottom: 0;
}

.customer-label-group {
  margin-bottom: 20px;
}

.form-label-header {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #2b2e38;
  line-height: 16px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  word-break: break-word;
}

.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 514px; */
}

.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.custom-outline-whatsapp-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 5px 6px;
  line-height: 10px;
}

.custom-outline-whatsapp-btn .whatsapp-color {
  color: #2ca270;
  font-size: 18px;
}

.custom-outline-call-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}

.custom-outline-call-btn .call-btn-color {
  color: #2ca270;
  font-size: 13px;
}

.custom-outline-ellipsis-btn {
  padding: 0;
  border: 0;
}

.text-star-blank {
  color: #c1c5c9;
}

.voter-report-section {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 12px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-top: 0;
}

.feedback-chart-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
}

.feedback-chart-outer .chart-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  padding: 16px;
}

.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}

.user-profile-table-col {
  width: 10%;
}

.user-name-table-col {
  width: 30%;
}
.voter-exit-box-outer {
  padding: 16px 16px 0;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
}

.customer-label-group {
  margin-bottom: 16px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}
.showmorebody {
  color: #3f51b5 !important;
  cursor: pointer;
}

.showlessbody {
  color: #3f51b5 !important;
  cursor: pointer;
}
</style>