import moment from 'moment';
export default {
  data() {
    return {};
  },
  methods: {
    maskedNumber(getvalue) {
      const maskedDigits = "X".repeat(getvalue.length - 6);
      const FirstThreeDigits = getvalue.slice(0, 3);
      const lastThreeDigits = getvalue.slice(-3);
      return FirstThreeDigits + maskedDigits + lastThreeDigits;
    },
    maskedEmail(getvalue) {
      const [name, domain] = getvalue.split("@");
      return `${name[0]}${new Array(name.length).join("*")}@${domain}`;
    },
    format_datetime(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm A");
      } else {
        return 'N/A';
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return 'N/A';
      }
    },
    format_timestamp(value) {
      if (value) {
        var localTime = new Date(value * 1000).toUTCString();
        return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return 'N/A';
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    isLetterWithSpace(e) {
      let charspace = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z\s]+$/.test(charspace)) return true;
      else e.preventDefault();
    },
    isLetterWithOutSpace(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    mobileSearchFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      var mobileInput = document.getElementById("searchmobInput");
      if (
        (mobileInput.value == "" || mobileInput.length > 1) &&
        (keyCode == 48 ||
          keyCode == 49 ||
          keyCode == 50 ||
          keyCode == 51 ||
          keyCode == 52)
      ) {
        $event.preventDefault();
      }
    },
    genderList: [
      { label: "Male", value: 1 },
      { label: "Female", value: 2 },
      { label: "Other", value: 3 },
    ],
  },
};
